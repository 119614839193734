import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    url:"https://taxandlicense.bremertonwa.gov/rest.php",
    url2:"https://ipn.paymentus.com/rotp/cbrl",
    url3:"https://landlords.bremertonwa.gov/",
    paytime:'',
    acct:'',
    authent:false,
    p:'',
    amountpaid:0,
    balanceforward:0,
  },
  mutations: {
    setacct(state,n){
      state.acct = n;
    },
    setAuth(state,n){
      state.authent = n;
    },
    setp(state,n){
      state.p = n;
    },
    setpaytime(state,n){
      state.paytime = n;
    },
    setAmountPaid(state,n){
      state.amountpaid = n;
    },
    setbf(state,n){
      state.balanceforward = n;
    }
  },
  actions: {
  },
  modules: {
  }
})
