<template>
<v-app>
  <div >
    <v-row justify="center" class="ma-8 pa-8">
      
      <v-card 
              width="600" 
              color="light-green lighten-4">
        <v-card-title>Edit Login Information</v-card-title>
        
        <v-card-text>
          Edit Email<br/>
          Change Password<br/>
          Update Contact Info
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-hover  v-slot:default="{ hover }" >
            <v-btn :elevation="hover ? 16 : 6" color="success" @click="el">Edit Login Information</v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-row>

    <v-row justify="center" class="ma-8 pa-8">
      <v-card 
              width="600" 
              color="light-green lighten-4">
                <v-card-title>
          <v-row>
            <v-col class="ma-0 pl-2 pt-0">{{acct.BusName}}</v-col>
            <v-col v-if="balanceforward>0" cols="4" class="mt-0 mr-4 pa-0">
              <v-row justify="end">
          <!-- //////////////////////////////////option to pay balance forward//////////////////////// -->
            <v-hover v-slot:default="{ hover }">
              <v-sheet 
                v-if="!pendingdate"
                color="warning" 
                rounded
                width="220"
                height="75"
                :elevation="hover ? 16 : 6"
                @click="bf">
                <v-row justify="center" class="mt-1"><small>Click here to pay</small></v-row>
                <v-row class="mt-0" justify="center">
                  <small>your balance owing</small><br/>
                </v-row>
                <v-row class="ma-0 pa-0" justify="center">
                  ${{balanceforwardnice}}
                </v-row>
              </v-sheet>
            </v-hover>
            <v-sheet 
                v-if="pendingdate"
                color="success" 
                rounded
                width="220"
                height="75"
                >
                <v-row class="mt-1" justify="center">
                  Payment of ${{balanceforwardnice}}<br/>
                </v-row>
                <v-row class="ma-0 pa-0" justify="center">
                  Pending receipt 
                </v-row>
              </v-sheet>
          </v-row>
          <!--  ///////////////////////////////////end option///////////////////////////////////////// -->
            </v-col>
            </v-row>
          </v-card-title>
        <v-card-text>
          <table>
          <tr><td>Account No.:</td><td><b>{{acct.AcctNo}}</b></td></tr>
          <tr><td>Tax Id: </td><td><b>{{acct.TaxId}}</b></td></tr>
          <tr><td>DBA: </td><td><b>{{acct.DBAName}}</b></td></tr>
          <tr><td >Business Description:</td><td><b>{{acct.BusDesc}}</b></td></tr>
          </table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row justify="space-around">
            <v-hover v-slot:default="{ hover }">
              <v-sheet 
                color="success" 
                rounded
                width="170"
                height="75"
                dark
                :elevation="hover ? 16 : 6"
                class="my-1"
                @click="pto">
                <v-row class="mt-3" justify="center">
                  File Tax Returns
                </v-row>
                <v-row justify="center">
                  Online / Phone / Mail
                </v-row>
              </v-sheet>
            </v-hover>
            <v-hover v-slot:default="{ hover }">  
              <v-sheet 
              color="success" 
                rounded
                width="170"
                height="75"
                dark
                :elevation="hover ? 16 : 6"
                class="my-1"
                @click="eb">
                  <v-row class="mt-3" justify="center"> 
                    Edit Business
                  </v-row>
                  <v-row justify="center">
                    Information
                  </v-row>
                </v-sheet>
            </v-hover>
            <v-hover v-slot:default="{ hover }"> 
                <v-sheet 
              color="success" 
                rounded
                width="170"
                height="75"
                dark
                :elevation="hover ? 16 : 6"
                class="my-1"
                @click="cb">
                  <v-row class="mt-3" justify="center"> 
                    Close
                  </v-row>
                  <v-row justify="center">
                    Business
                  </v-row>
                </v-sheet>
              </v-hover>  
           </v-row>  
          </v-card-actions>
        </v-card>
      
    </v-row>
  </div>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
    data: () => ({
    acct:[],
    balanceforward:0,
    balanceforwardnice:0,
    pendingdate:'none'
    }),
    methods:{
      getaccount(){
        this.acct = this.$store.state.acct;
                
          let thisvue=this;
          let params = new URLSearchParams();
          let url = this.$store.state.url;
          let acnt=this.acct.AcctNo;
          params.append('ty', 'getaccountbalance');
          params.append('acnt', acnt);
          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){

            thisvue.balanceforward=response.data[0].toFixed(2);
            thisvue.balanceforwardnice=thisvue.balanceforward.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            thisvue.pendingdate=response.data[1];
          });
         
      },
      eb(){
        this.$router.replace({ name: "edit" });
      },
      el(){
        this.$router.replace({ name: "account" });
      },
      pto(){
        this.$router.replace({ name: "paytax" });
      },
      cb(){
        this.$router.replace({ name: "close" });
      },
      bf(){
        this.$store.commit('setbf',this.balanceforward);
        this.$router.replace({ name: "bf" });
      }
    },
    mounted(){
      window.scrollTo(0,0);
      
      let a= this.$store.state.authent;
      if(!a) {
        this.$router.replace({ name: "login" });
      }else{
        this.getaccount();
      }
      
    }
}
</script>
