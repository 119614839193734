<template >
  <div id="app" >
    <Navbar />
    <transition
        name="fade"
        mode="out-in"
      >
        <router-view/>
      </transition>
    <Footer />
    
  </div>
</template>
<script>
import Navbar from "./components/Navbar";
import Footer from "./components/footer";


export default {
  components: {
    Navbar,
    Footer
    
  },
  data() {
      return {
        lid:'',
         eml: '',
         preparerName:'',
         parcel:'',
         closedate:'',
         addresses:[],
         parceldata:[],
         prikey:'',
         clickedaddress:''
        }
   },
   created(){
    
   },
   mounted() {
      
      //window.scrollTo(0,0);
      //let a= this.$store.state.authent;
      //if(!a) {
      //  this.$router.replace({ name: "login" });
      //}else{
      // this.$router.replace({ name: "Home" });
      //}
  },
    methods: {
        
        logout() {

            this.$store.commit('setAuth',false);
            this.$router.go();
        }
    }
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>
