<template>
<div ref="hdiv" v-bind:style="{'height':divh}"> 
  <v-card class="overflow-hidden">
    <v-app-bar
      fixed
      color="#00897b"
      dark
      shrink-on-scroll
      src="https://picsum.photos/1920/1080?random"
      fade-img-on-scroll
      scroll-threshold="200" 
      elevate-on-scroll
     >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(55,236,186,.7), rgba(25,32,72,.7)"
        ></v-img>
      </template>

      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

      <v-toolbar-title>Bremerton Tax and License</v-toolbar-title>

      <v-spacer></v-spacer>
    
      <router-link to="about">
        <v-btn icon>
          <v-icon>mdi-help-box</v-icon>
        </v-btn>
      </router-link>

      
        <v-btn icon @click="gohome">
          <v-icon>mdi-home</v-icon>
        </v-btn>
      

      
        <v-btn icon @click="logout">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
     
    
    </v-app-bar>
    
  </v-card>
  <div style="height:50px" ></div>
  &nbsp;
  
</div>
</template>
<script>

export default {
  data(){
    return{
      divh:"125px"
    }
  },
  methods:{
    logout(){
     this.$parent.logout();

    },
    gohome(){
       //this.$store.commit('setacct',a);
       this.$router.replace({ name: "Home" });
    }
  }
    
  };

</script>
<style scoped>
a {  text-decoration: none;}
</style>


