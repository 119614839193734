var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',[_c('v-row',{staticClass:"ma-8 pa-8",attrs:{"justify":"center"}},[_c('v-card',{attrs:{"width":"600","color":"light-green lighten-4"}},[_c('v-card-title',[_vm._v("Edit Login Information")]),_c('v-card-text',[_vm._v(" Edit Email"),_c('br'),_vm._v(" Change Password"),_c('br'),_vm._v(" Update Contact Info ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{attrs:{"elevation":hover ? 16 : 6,"color":"success"},on:{"click":_vm.el}},[_vm._v("Edit Login Information")])]}}])})],1)],1)],1),_c('v-row',{staticClass:"ma-8 pa-8",attrs:{"justify":"center"}},[_c('v-card',{attrs:{"width":"600","color":"light-green lighten-4"}},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"ma-0 pl-2 pt-0"},[_vm._v(_vm._s(_vm.acct.BusName))]),(_vm.balanceforward>0)?_c('v-col',{staticClass:"mt-0 mr-4 pa-0",attrs:{"cols":"4"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(!_vm.pendingdate)?_c('v-sheet',{attrs:{"color":"warning","rounded":"","width":"220","height":"75","elevation":hover ? 16 : 6},on:{"click":_vm.bf}},[_c('v-row',{staticClass:"mt-1",attrs:{"justify":"center"}},[_c('small',[_vm._v("Click here to pay")])]),_c('v-row',{staticClass:"mt-0",attrs:{"justify":"center"}},[_c('small',[_vm._v("your balance owing")]),_c('br')]),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_vm._v(" $"+_vm._s(_vm.balanceforwardnice)+" ")])],1):_vm._e()]}}],null,false,4187172729)}),(_vm.pendingdate)?_c('v-sheet',{attrs:{"color":"success","rounded":"","width":"220","height":"75"}},[_c('v-row',{staticClass:"mt-1",attrs:{"justify":"center"}},[_vm._v(" Payment of $"+_vm._s(_vm.balanceforwardnice)),_c('br')]),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_vm._v(" Pending receipt ")])],1):_vm._e()],1)],1):_vm._e()],1)],1),_c('v-card-text',[_c('table',[_c('tr',[_c('td',[_vm._v("Account No.:")]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.acct.AcctNo))])])]),_c('tr',[_c('td',[_vm._v("Tax Id: ")]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.acct.TaxId))])])]),_c('tr',[_c('td',[_vm._v("DBA: ")]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.acct.DBAName))])])]),_c('tr',[_c('td',[_vm._v("Business Description:")]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.acct.BusDesc))])])])])]),_c('v-divider'),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{staticClass:"my-1",attrs:{"color":"success","rounded":"","width":"170","height":"75","dark":"","elevation":hover ? 16 : 6},on:{"click":_vm.pto}},[_c('v-row',{staticClass:"mt-3",attrs:{"justify":"center"}},[_vm._v(" File Tax Returns ")]),_c('v-row',{attrs:{"justify":"center"}},[_vm._v(" Online / Phone / Mail ")])],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{staticClass:"my-1",attrs:{"color":"success","rounded":"","width":"170","height":"75","dark":"","elevation":hover ? 16 : 6},on:{"click":_vm.eb}},[_c('v-row',{staticClass:"mt-3",attrs:{"justify":"center"}},[_vm._v(" Edit Business ")]),_c('v-row',{attrs:{"justify":"center"}},[_vm._v(" Information ")])],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{staticClass:"my-1",attrs:{"color":"success","rounded":"","width":"170","height":"75","dark":"","elevation":hover ? 16 : 6},on:{"click":_vm.cb}},[_c('v-row',{staticClass:"mt-3",attrs:{"justify":"center"}},[_vm._v(" Close ")]),_c('v-row',{attrs:{"justify":"center"}},[_vm._v(" Business ")])],1)]}}])})],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }